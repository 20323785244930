<template>
  <span>{{ amount }}{{ currency }}</span>
</template>

<script>

export default {
  props: {
    value: {
      type: Number,
    },
    currency: {
      type: String,
      default: '€',
    },
  },
  computed: {
    amount () {
      return ((this.value || 0) / 100).toFixed(2);
    },
  },
};

</script>
