<template>
  <main v-if="country">
    <PageTitleContainer>
      <template #default>
        <PageTitle>{{ country.name }}</PageTitle>
      </template>
      <template #actions>
        <router-link class="btn btn-outline-primary" :to="{ name: 'settings.countries.show', params: { code: country.code } }">Visualizza</router-link>
      </template>
    </PageTitleContainer>

    <div class="container">
      <div class="row">
        <div class="col">
          <p><strong>Attivo</strong>: {{ country.enabled ? 'Sì' : 'No' }}</p>
        </div>
        <div class="col">
          <p><strong>Valuta</strong>: {{ country.currencyName }} ({{ country.currencyCode }})</p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <strong>Aliquote IVA</strong>
          <div v-if="!country.vatRates || country.vatRates.length === 0">Nessuna aliquota impostata</div>
          <div v-else-if="country.vatRates.length === 1 && !country.vatRates[0].min && !country.vatRates[0].max">
            {{ country.vatRates[0].vatRate }}%
          </div>
          <template v-else>
            <div v-for="(vatRate, pos) in country.vatRates" :key="'vatRate-' + pos">
              {{ vatRate.vatRate }}% ({{ vatRate.min }}-{{ vatRate.max }})
            </div>
          </template>
        </div>
        <div class="col">
          <strong>Accise sul vino</strong>
          <div v-if="!country.exciseDuties || country.exciseDuties.length === 0">Nessuna accisa impostata</div>
          <div v-else-if="country.exciseDuties.length === 1 && !country.exciseDuties[0].min && !country.exciseDuties[0].max">
            {{ country.exciseDuties[0].exciseDuty / 100 }} {{ country.currencyCode }}/HL
          </div>
          <template v-else>
            <div v-for="(exciseDuty, pos) in country.exciseDuties" :key="'exciseDuty-' + pos">
              {{ exciseDuty.exciseDuty / 100 }} {{ country.currencyCode }}/HL ({{ exciseDuty.min }}-{{ exciseDuty.max }})
            </div>
          </template>
        </div>
        <div class="col">
          <strong>Accise sugli spumanti</strong>
          <div v-if="!country.sparklingExciseDuties || country.sparklingExciseDuties.length === 0">Nessuna accisa impostata</div>
          <div v-else-if="country.sparklingExciseDuties.length === 1 && !country.sparklingExciseDuties[0].min && !country.sparklingExciseDuties[0].max">
            {{ country.sparklingExciseDuties[0].exciseDuty / 100 }} {{ country.currencyCode }}/HL
          </div>
          <template v-else>
            <div v-for="(exciseDuty, pos) in country.sparklingExciseDuties" :key="'exciseDuty-' + pos">
              {{ exciseDuty.exciseDuty / 100 }} {{ country.currencyCode }}/HL ({{ exciseDuty.min }}-{{ exciseDuty.max }})
            </div>
          </template>
        </div>
      </div>
    </div>

    <AwlTable class="mt-4" :heading="tableHeadings" :rows="products" striped>
      <template #column-key-wineTypeName="{ value, row }">
        {{ value }}<span v-if="row.alcoholContent"> ({{ row.alcoholContent }}°)</span>
        <span v-if="row.wineSparkling"> [Spumante]</span>
      </template>

      <template #column-key-purchasePrice="{ value }">
        <CurrencyValue class="d-block text-end" :value="value" currency="" />
      </template>

      <template #column-key-defaultSellingPrice="{ value }">
        <CurrencyValue class="d-block text-end" :value="value" currency="" />
      </template>

      <template #column-key-basePrice="{ value }">
        <CurrencyValue class="d-block text-end" :value="value" currency="" />
      </template>

      <template #column-key-exciseDuty="{ value }">
        <CurrencyValue class="d-block text-end" :value="value" currency="" />
      </template>

      <template #column-key-priceWithExciseDuty="{ value }">
        <CurrencyValue class="d-block text-end" :value="value" currency="" />
      </template>

      <template #column-key-vatApplied="{ value }">
        <CurrencyValue class="d-block text-end" :value="value" currency="" />
      </template>

      <template #column-key-price="{ value }">
        <CurrencyValue class="d-block text-end fw-bold" :value="value" currency="" />
      </template>
    </AwlTable>
  </main>
  <span v-else></span>
</template>

<script>

import $api from '@/libs/OAuth2/services/api.js';
import PageTitleContainer from '@/views/components/Private/PageTitleContainer.vue';
import PageTitle from '@/views/components/Private/PageTitle.vue';
import AwlTable from '@/libs/Table/components/AwlTable.vue';
import CurrencyValue from '@/views/components/Ui/CurrencyValue.vue';

function beforeRouteEnterOrUpdate (to, from, next) {
  if (!to?.params?.code) {
    return next(from);
  }

  Promise.all([
    $api.fetchCountry(to.params.code),
    $api.getCountryProductPrices(to.params.code),
  ])
    .then(([resFetchCountry, resGetCountryProductPrices]) => next(vm => {
      vm.country = resFetchCountry?.data;
      vm.products = resGetCountryProductPrices?.data?.products || [];
    }))
    .catch(() => next(from))
  ;
}

export default {
  components: { PageTitleContainer, PageTitle, AwlTable, CurrencyValue },
  data () {
    return {
      loading: false,
      country: null,
      products: null,
    };
  },
  computed: {
    tableHeadings () {
      return [
        { key: 'producerName', label: 'Produttore' },
        { key: 'wineName', label: 'Vino' },
        { key: 'vintageName', label: 'Anno' },
        { key: 'alcoholContent', label: '°' },
        { key: 'purchasePrice', label: 'Costo eur' },
        { key: 'defaultSellingPrice', label: 'Prezzo eur' },
        { key: 'basePrice', label: 'Prezzo ' + (this.country?.currencyCode || ''), hidden: !this.country?.currencyCode || this.country.currencyCode.toLowerCase() === 'eur' },
        { key: 'exciseDuty', label: 'Accise ' + (this.country?.currencyCode || '') },
        { key: 'priceWithExciseDuty', label: 'Prezzo + accise ' + (this.country?.currencyCode || '') },
        { key: 'vatApplied', label: 'IVA ' + (this.country?.currencyCode || '')  },
        { key: 'price', label: 'Prezzo finale ' + (this.country?.currencyCode || '')  },
      ];
    },
  },
  beforeRouteEnter: beforeRouteEnterOrUpdate,
  beforeRouteUpdate: beforeRouteEnterOrUpdate,
};

</script>
